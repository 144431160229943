import ContactAddIcon from '../../../shared/components/icons/ContactAddIcon';
import { ILayoutActionItem } from '../../../shared/components/layout/pages/types';
import useModal from '../../../shared/components/modal/hooks/useModal';
import { ContactCreateModal } from '../ContactCreateModal';

export function useContactsActions(): ILayoutActionItem[] {
  const { openModal } = useModal();

  return [
    {
      id: 'create',
      text: 'Add contact',
      icon: ContactAddIcon,
      onClick: () => {
        openModal({ content: <ContactCreateModal /> });
      },
    },
  ];
}
