import { X } from 'lucide-react';
import { FiltersSettings } from './hooks/types';
import { Pill } from '../indicators/Pill';
import FlexContainer from '../structure/FlexContainer';
import Text from '../text/Text';

interface FilterContainerProps<TFilter> {
  filter: FiltersSettings<TFilter>;
}

export default function FiltersActiveList<TFilter>({ filter }: FilterContainerProps<TFilter>) {
  const hasFilters = Object.keys(filter.filterState as object).length > 0;

  return (
    <>
      {hasFilters && filter.filterState && (
        <>
          {(Object.keys(filter.filterState) as Array<keyof TFilter>).map((key) => (
            <Pill key={key as string} color="green" onClick={() => filter.clearFilter(key)}>
              <FlexContainer align="center" justify="center" gap="none" className="space-x-1">
                <X className="w-5 h-5 opacity-60" />
                <Text size="xSmall">
                  <span className="opacity-60">
                    {filter.filters.find((f) => f.key === key)?.label} :{' '}
                  </span>
                  {String(filter.filterState[key])}
                </Text>
              </FlexContainer>
            </Pill>
          ))}
          <Pill color="white" onClick={filter.clearAllFilters}>
            <FlexContainer align="center" justify="center" gap="small">
              <X className="w-5 h-5" />
              <Text size="xSmall">Clear Filters</Text>
            </FlexContainer>
          </Pill>
        </>
      )}
    </>
  );
}
