import { useEffect, useState } from 'react';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { useUpdateContactMutation } from './queries/contactQueries';
import { REGEX_EMAIL } from '../../shared/constants/RegexValidations';
import Button from '../../shared/components/buttons/Button';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import { IContactDto } from './models/IContactDto';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import useDebounce from '../../shared/hooks/useDebounce';
import { useCreateInvitationMutation } from '../Request/queries/invitationQueries';
import Text from '../../shared/components/text/Text';
import HorizontalSpacer from '../../shared/components/spacing/HorizontalSpacer';
import { UserProfileDto } from '../Profile/models/UserProfile';
import { searchUsersQueryBase } from '../Profile/queries/userProfileQueries';
import { UnsavedDataAlertDialog } from '../../shared/components/alert-dialog/UnsavedDataAlertDialog';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import { ProfileCard } from '../../shared/components/cards/ProfileCard';
import { useConnectWithUserCardAction } from './hooks/contactProfileCardActionHooks';
import { Checkbox } from '../../shared/components/checkbox/Checkbox';

interface IExistingProfileCardProps {
  userProfile: UserProfileDto;
  contactId: string;
  onSuccess: () => void;
}

function ExistingProfileCard({ userProfile, contactId, onSuccess }: IExistingProfileCardProps) {
  return (
    <ProfileCard
      profile={userProfile}
      as="div"
      actionsPosition="inline"
      actions={[useConnectWithUserCardAction({ userProfile, contactId, onSuccess })]}
    />
  );
}

interface IProps {
  contact: IContactDto;
  onCancel: () => void;
  onSuccess?: () => void;
}

// FIXME: isDirty is bugged because the fields of IContactDto does not match the form fields.
export default function ContactEditModal({ contact, onCancel, onSuccess }: IProps) {
  const [showUnsavedDataWarning, setShowUnsavedDataWarning] = useState(false);
  // TODO: Handle if a user is already subscribed by the contact.
  const [emailCheckState, setEmailCheckState] = useState({
    isVerified: false,
    isConfirmSendEmailCheckboxChecked: false,
    user: undefined as UserProfileDto | undefined,
  });

  const queryClient = useQueryClient();
  const createInvitationMutation = useCreateInvitationMutation();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    getFieldState,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm<IContactDto>({
    mode: 'onChange',
    values: contact,
  });

  const { fields } = useFieldArray({
    control,
    name: 'emailAddresses',
  });

  const inviteEmailAddress = watch('emailAddresses.0.value');
  const emailFieldState = getFieldState('emailAddresses.0.value');
  const debouncedEmailState = useDebounce({
    email: inviteEmailAddress,
    invalid: emailFieldState.invalid,
  });
  const updateContactMutation = useUpdateContactMutation();

  useEffect(() => {
    if (debouncedEmailState.invalid) return;

    setEmailCheckState(() => ({
      isVerified: false,
      isConfirmSendEmailCheckboxChecked: false,
      user: undefined,
    }));

    if (debouncedEmailState.email !== '') {
      queryClient
        .fetchQuery(searchUsersQueryBase({ searchTerm: debouncedEmailState.email, limit: 1 }))
        .then((result) => {
          if (result.length === 1) {
            setEmailCheckState(() => ({
              isVerified: true,
              isConfirmSendEmailCheckboxChecked: false,
              user: result[0],
            }));
          } else {
            setEmailCheckState(() => ({
              isVerified: true,
              isConfirmSendEmailCheckboxChecked: false,
              user: undefined,
            }));
          }
        });
    }
  }, [debouncedEmailState.email, debouncedEmailState.invalid]);

  const handleContinueClosing = () => {
    reset();
    onCancel();
  };

  const onSubmit: SubmitHandler<IContactDto> = (updatedContact) => {
    const _updatedContact = {
      ...updatedContact,
      emailAddresses: updatedContact.emailAddresses.filter((email) => email.value),
      phoneNumbers: updatedContact.phoneNumbers.filter((phone) => phone.value),
    };
    updateContactMutation.mutate(
      { contact: _updatedContact, id: contact.id },
      {
        onSuccess: () => {
          reset({}, { keepValues: true });
          if (onSuccess) {
            onSuccess();
          }
          onCancel();
        },
      },
    );
  };

  const handleInviteUser = () => {
    createInvitationMutation.mutate(
      { email: inviteEmailAddress },
      {
        onSuccess: () => handleContinueClosing(),
      },
    );
  };

  const handleCancelEditContact = () => {
    if (isDirty) {
      setShowUnsavedDataWarning(true);
    } else {
      onCancel();
    }
  };

  return (
    <>
      <ScrollBarWrapper>
        <form onSubmit={handleSubmit(onSubmit)} className="text-left space-y-8">
          <div>
            <div className="flex sm:grid sm:grid-cols-6 items-end gap-y-6 gap-x-4">
              <div className="flex-1 sm:col-span-3">
                {fields.map((field, index) => (
                  <TextFieldInput
                    key={field.id}
                    labelText="Email"
                    name={`emailAddresses.${index}.value`}
                    type="text"
                    register={register}
                    errors={errors}
                    validationRules={{
                      pattern: { value: REGEX_EMAIL, message: 'Must be a valid email' },
                      required: 'Email is required',
                    }}
                  />
                ))}
              </div>
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <Button
                    color={ButtonColors.White}
                    text="Invite"
                    icon={<EnvelopeIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />}
                    onClick={handleInviteUser}
                    disabled={!emailCheckState.isConfirmSendEmailCheckboxChecked}
                  />
                </div>
              </div>
            </div>
            <HorizontalSpacer />
            <Text as="p" size="small" brightness="light">
              {!emailCheckState.user ? (
                <>
                  {!emailCheckState.isVerified ? (
                    <>Enter an email to see if the contact exists in Yoin.</>
                  ) : (
                    <>
                      The contact does not exist in Yoin. You can invite the contact to Yoin by
                      pressing the &quot;Invite&quot;-button.
                    </>
                  )}
                </>
              ) : (
                <>
                  {emailCheckState.isVerified &&
                  emailCheckState.user.emailAddresses.some(
                    (detail) => detail.value === debouncedEmailState.email,
                  )
                    ? `The user ${emailCheckState.user.displayName} exists`
                    : 'Enter an email to see if the contact exists in Yoin.'}
                </>
              )}
            </Text>
            {emailCheckState.isVerified &&
              !emailCheckState.user &&
              !debouncedEmailState.invalid && (
                <div className="relative flex items-start my-4">
                  <div className="flex h-5 items-center">
                    <Checkbox
                      id="verify"
                      aria-describedby="verify-description"
                      name="verify"
                      onCheckedChange={(checked) =>
                        setEmailCheckState((curr) => ({
                          ...curr,
                          isConfirmSendEmailCheckboxChecked: Boolean(checked),
                        }))
                      }
                    />
                  </div>
                  <div className="ml-3">
                    <Text as="p" size="small" brightness="normal">
                      I am sure I want to send an invite to this email address
                    </Text>
                  </div>
                </div>
              )}
            {emailCheckState.isVerified &&
              emailCheckState.user &&
              !debouncedEmailState.invalid &&
              emailCheckState.user.emailAddresses.some(
                (detail) => detail.value === debouncedEmailState.email,
              ) && (
                <div className="flex flex-row flex-1 space-x-0 md:space-x-3">
                  <ExistingProfileCard
                    userProfile={emailCheckState.user}
                    contactId={contact.id}
                    onSuccess={handleContinueClosing}
                  />
                </div>
              )}
          </div>
          <div>
            <Text as="h3" size="large" leading="normal" weight="medium">
              Personal Information
            </Text>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <TextFieldInput
                  labelText="First Name"
                  name="firstName"
                  type="text"
                  register={register}
                  errors={errors}
                  validationRules={{
                    required: 'Is required',
                    minLength: { value: 2, message: 'Must be between 2 and 50 characters' },
                    maxLength: { value: 50, message: 'Must be between 2 and 50 characters' },
                  }}
                />
              </div>
              <div className="sm:col-span-3">
                <TextFieldInput
                  labelText="Last Name"
                  name="lastName"
                  type="text"
                  register={register}
                  errors={errors}
                  validationRules={{
                    required: 'Is required',
                    minLength: { value: 2, message: 'Must be between 2 and 50 characters' },
                    maxLength: { value: 50, message: 'Must be between 2 and 50 characters' },
                  }}
                />
              </div>
              <div className="sm:col-span-3">
                <TextFieldInput
                  labelText="Phone Number"
                  name="phoneNumbers.0.value"
                  type="text"
                  register={register}
                  validationRules={{
                    required: 'Is required',
                    minLength: { value: 2, message: 'Must be between 2 and 20 characters' },
                    maxLength: { value: 20, message: 'Must be between 2 and 20 characters' },
                  }}
                />
              </div>
              <div className="sm:col-span-3" />
              <div className="sm:col-span-3">
                <TextFieldInput
                  labelText="Location"
                  name="location"
                  type="text"
                  register={register}
                  validationRules={{
                    required: 'Is required',
                    minLength: {
                      value: 2,
                      message: 'Must be between 2 and 50 characters',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Must be between 2 and 50 characters',
                    },
                  }}
                />
              </div>
              <div className="sm:col-span-3" />
              <div className="sm:col-span-3">
                <TextFieldInput
                  labelText="Company"
                  name="company"
                  type="text"
                  register={register}
                />
              </div>
              <div className="sm:col-span-3">
                <TextFieldInput labelText="Title" name="title" type="text" register={register} />
              </div>
            </div>
          </div>
          <div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <div className="mt-1">
                  <TextAreaInput
                    name="additionalInformation"
                    register={register}
                    labelText="Additional Information"
                  />
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end gap-x-3">
                <Button
                  color={ButtonColors.White}
                  text="Cancel"
                  onClick={handleCancelEditContact}
                />
                <SubmitButton
                  text="Save"
                  isDisabled={!isValid || !isDirty || updateContactMutation.isPending}
                />
              </div>
            </div>
          </div>
        </form>
      </ScrollBarWrapper>

      <UnsavedDataAlertDialog
        open={showUnsavedDataWarning}
        onOpenChange={setShowUnsavedDataWarning}
        onLeaveClick={handleContinueClosing}
      />
    </>
  );
}
