// Kind of temporary experiment. Should be a floating menu with buttons.
// And buttons should some kind of variant with icon button.

import { cva, VariantProps } from 'class-variance-authority';
import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cn } from '../../../lib/utils';

const fabVariants = cva('bottom-8 right-4 p-2 focus:outline-none', {
  variants: {
    position: {
      fixed: 'fixed',
      absolute: 'absolute',
    },
  },
  defaultVariants: {
    position: 'absolute',
  },
});

export interface IFloatingActionButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'>,
    VariantProps<typeof fabVariants> {
  asChild?: boolean;
  text: string;
  icon: React.ElementType;
}

const FloatingActionButton = React.forwardRef<HTMLButtonElement, IFloatingActionButtonProps>(
  ({ position, icon: Icon, className = '', asChild = false, text, ...props }, ref) => {
    const Component = asChild ? Slot : 'button';
    return (
      <Component ref={ref} className={cn(fabVariants({ position, className }))} {...props}>
        <Icon alt="test" className="w-12 h-12" />
        <span className="sr-only">{text}</span>
      </Component>
    );
  },
);
FloatingActionButton.displayName = 'FloatingActionButton';

export { FloatingActionButton };
