import { Bars3Icon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { useParams } from 'react-router';
import { BaseLayout } from '../../shared/components/layout/pages/BaseLayout';
import ProjectsSearch from '../Projects/ProjectsSearch';
import { useOrganizationQuery } from './queries/organizationQueries';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import useCompanyBreadcrumbNavigation from './hooks/useCompanyBreadcrumbNavigation';
import DropdownNavigator from '../../shared/components/navigation/DropdownNavigator';
import useCompanyNavigation from './hooks/useCompanyNavigation';
import CompanySidePanel from './CompanySidePanel';
import WrapperDesktop from '../../shared/components/screen-discriminators/WrapperDesktop';
import { IActionItem } from '../../shared/model/IActionItem';
import AddIcon from '../../shared/components/icons/AddIcon';
import useModal from '../../shared/components/modal/hooks/useModal';
import ProjectCreateEditModal from '../Projects/ProjectCreateEditModal';

function useCompanyActions() {
  const setActiveViewLayout = useGlobalStore((state) => state.setActiveViewLayout);
  const { openModal } = useModal();
  const activeViewLayout = useGlobalStore(
    (state) => state.getViewSettings('project').activeViewLayout,
  );

  const headerActions: IActionItem[] = [
    {
      id: 'projects',
      title: 'Projects',
      type: 'header',
    },
    {
      id: 'add',
      title: 'Add project',
      icon: AddIcon,
      onClick: () =>
        openModal({
          title: 'Create new project',
          content: <ProjectCreateEditModal />,
        }),
    },
    {
      id: 'view',
      title: 'Change view',
      type: 'header',
    },

    activeViewLayout === 'card'
      ? {
          id: 'listView',
          title: 'List view',
          onClick: () => {
            setActiveViewLayout('project', 'list');
            return Promise.resolve();
          },
          icon: Bars3Icon,
        }
      : {
          id: 'cardView',
          title: 'Card view',
          onClick: () => {
            setActiveViewLayout('project', 'card');
            return Promise.resolve();
          },
          icon: Squares2X2Icon,
        },
  ];

  return headerActions;
}

export default function Company() {
  const { tenantId } = useParams();
  const { breadcrumbNavigatorItems } = useCompanyBreadcrumbNavigation();
  const { items: companyNavItems } = useCompanyNavigation();

  const organizationQuery = useOrganizationQuery(tenantId as string);
  const organization = organizationQuery.data;

  const headerActions = useCompanyActions();

  if (!organization) {
    return <></>;
  }

  return (
    <>
      <BaseLayout
        title={organizationQuery.data?.name}
        headerActions={headerActions}
        breadcrumbsNavigatorItems={breadcrumbNavigatorItems}
        contentAfterHeader={<DropdownNavigator items={companyNavItems} />}
      >
        <ProjectsSearch />
      </BaseLayout>
      <WrapperDesktop>
        <CompanySidePanel />
      </WrapperDesktop>
    </>
  );
}
