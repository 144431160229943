import contactIcon from '../../assets/images/contact-icon.svg';
import contactPassiveIcon from '../../assets/images/contact-passive-icon.svg';
import networkIcon from '../../assets/images/network-icon.svg';
import pipelineIcon from '../../assets/images/pipeline-icon.svg';
import projectIcon from '../../assets/images/project-icon.svg';
import boardIcon from '../../assets/images/project-board-icon.svg';
import pipelineItemIcon from '../../assets/images/pipeline-item-icon.svg';
import organizationIcon from '../../assets/images/company-icon.svg';
import opportunityIcon from '../../assets/images/opportunity-icon.svg';
import { ResourceType } from '../model/ResourceType';

export interface IColorSetting {
  text: string;
  background: string;
}

export type ResourceIds = string | string[];

export interface IEntityManifest {
  name: ResourceType;
  endpointName: string;
  parent?: ResourceType;
  icon: string;
  color: IColorSetting;
  hasTenantInResourceLink?: boolean;
  supportsPhoto: boolean;
  supportsActivities: boolean;
  supportsMembers: boolean;
  supportsVisits: boolean;
}

const entityManifests: IEntityManifest[] = [
  {
    name: ResourceType.Unknown,
    endpointName: '',
    icon: contactIcon,
    color: { background: 'bg-white', text: '!text-cyan-dark' },
    supportsPhoto: false,
    supportsActivities: false,
    supportsMembers: false,
    supportsVisits: false,
  },
  {
    name: ResourceType.Contact,
    endpointName: 'contacts',
    icon: contactIcon,
    color: { background: 'bg-green', text: 'text-white' },
    supportsPhoto: true,
    supportsActivities: true,
    supportsMembers: false,
    supportsVisits: false,
  },
  {
    name: ResourceType.ContactPassive,
    endpointName: 'contacts',
    parent: ResourceType.Contact,
    icon: contactPassiveIcon,
    color: { background: 'bg-yellow', text: 'text-white' },
    supportsPhoto: true,
    supportsActivities: false,
    supportsMembers: false,
    supportsVisits: false,
  },
  {
    name: ResourceType.Network,
    endpointName: 'networks',
    icon: networkIcon,
    color: { background: 'bg-purple', text: 'text-white' },
    supportsPhoto: true,
    supportsActivities: true,
    supportsMembers: true,
    supportsVisits: true,
  },
  {
    name: ResourceType.Pipeline,
    endpointName: 'pipelines',
    icon: pipelineIcon,
    color: { background: 'bg-cyan', text: 'text-white' },
    supportsPhoto: false,
    supportsActivities: false,
    supportsMembers: false,
    supportsVisits: false,
  },
  {
    name: ResourceType.PipelineStage,
    endpointName: 'stages',
    parent: ResourceType.Pipeline,
    icon: pipelineIcon,
    color: { background: 'bg-cyan', text: 'text-white' },
    supportsPhoto: false,
    supportsActivities: false,
    supportsMembers: false,
    supportsVisits: false,
  },
  {
    name: ResourceType.PipelineItem,
    endpointName: 'items',
    parent: ResourceType.PipelineStage,
    icon: pipelineItemIcon,
    color: { background: 'bg-cyan', text: 'text-white' },
    supportsPhoto: false,
    supportsActivities: true,
    supportsMembers: false,
    supportsVisits: false,
  },
  {
    name: ResourceType.Organization,
    endpointName: 'companies',
    icon: organizationIcon,
    color: { background: 'bg-cyan', text: 'text-white' },
    supportsPhoto: false,
    supportsActivities: false,
    supportsMembers: true, // Kind of
    supportsVisits: false,
  },
  {
    name: ResourceType.Project,
    endpointName: 'projects',
    hasTenantInResourceLink: true,
    icon: projectIcon,
    color: { background: 'bg-cyan', text: 'text-white' },
    supportsPhoto: true,
    supportsActivities: true,
    supportsMembers: true,
    supportsVisits: true,
  },
  {
    name: ResourceType.Board,
    endpointName: 'boards',
    parent: ResourceType.Project,
    icon: boardIcon,
    color: { background: 'bg-cyan', text: 'text-white' },
    supportsPhoto: true,
    supportsActivities: true,
    supportsMembers: true,
    supportsVisits: true,
  },
  {
    name: ResourceType.Opportunity,
    endpointName: 'opportunity',
    parent: ResourceType.Board,
    icon: opportunityIcon,
    color: { background: 'bg-cyan', text: 'text-white' },
    supportsPhoto: true,
    supportsActivities: false,
    supportsMembers: true,
    supportsVisits: true,
  },
];

const entityManifestLookup: { [key: string]: IEntityManifest } = {};
entityManifests.forEach((manifest) => {
  entityManifestLookup[manifest.name] = manifest;
});

const lookupEntityManifest = (type: ResourceType) => {
  const manifest = entityManifestLookup[type];
  if (!manifest) {
    return entityManifestLookup[ResourceType.Unknown];
  }

  return manifest;
};

const resolveEntityManifestChain = (leafType: ResourceType) => {
  let manifest = lookupEntityManifest(leafType);
  const manifests = [manifest];
  while (manifest.parent) {
    manifest = lookupEntityManifest(manifest.parent);
    manifests.push(manifest);
  }
  // Reverse to get it from leaf > parent1 > root to root > parent1 > leaf
  return manifests.reverse();
};

export const resolveEndpointName = (type: ResourceType, resourceIds: ResourceIds) => {
  const manifests = resolveEntityManifestChain(type);
  const actualResourceIds = Array.isArray(resourceIds) ? resourceIds : [resourceIds];

  if (manifests.length !== actualResourceIds.length) {
    throw new Error(
      `Trying to resolve an endpoint name for a resource where the parent chain and the available resourceIds does not match. ManifestChainCount: ${manifests.length} and ResourceIdCount: ${resourceIds.length}`,
    );
  }

  const resolvedPath = manifests.map(
    (manifest, index) => `${manifest.endpointName}/${actualResourceIds[index]}`,
  );

  return resolvedPath.join('/');
};

export const resolveResourceLink = (
  type: ResourceType,
  tenantId: string,
  resourceIds: ResourceIds,
) => {
  // In future we may need to seperate resolving endpoint name and resource link
  const endpoint = resolveEndpointName(type, resourceIds);
  const manifest = resolveEntityManifestChain(type);
  // Check if root resource require tenant information for navigation
  if (manifest[0].hasTenantInResourceLink) {
    return ['companies', tenantId, endpoint].join('/');
  }
  return endpoint;
};

const useEntityManifest = (resourceType?: ResourceType): IEntityManifest => {
  if (!resourceType) {
    return entityManifestLookup[ResourceType.Unknown];
  }

  return entityManifestLookup[resourceType] || entityManifestLookup[ResourceType.Unknown];
};

export default useEntityManifest;
