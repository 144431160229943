import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  ShareIcon as ShareIconSolid,
  UsersIcon as UsersIconSolid,
  BuildingOffice2Icon as BuildingOfficeIconSolid,
} from '@heroicons/react/24/solid';
import { ShareIcon, UsersIcon, BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { ISidebarNavItem } from '../../../../model/ISidebarNavItem';
import { useOrganizationsQuery } from '../../../../../features/Companies/queries/organizationQueries';
import { AppContext } from '../../../../context/context';
import {
  connectionConversationFilter,
  networkConversationFilter,
} from '../../../../filters/filters';
import { ResourceType } from '../../../../model/ResourceType';
import useEntityManifest from '../../../../hooks/useEntityManifest';
import CompanyNavActions from '../../../../../features/Companies/CompanyNavActions';
import { createRoute, isCurrentRouteActive } from '../../../navigation/helpers/routingHelpers';

const staticNavItems: Array<ISidebarNavItem> = [
  {
    name: 'Contacts',
    route: 'contacts',
    icon: UsersIcon,
    activeIcon: UsersIconSolid,
    current: false,
    conversationFilter: connectionConversationFilter,
  },
  {
    name: 'Networks',
    route: 'networks',
    icon: ShareIcon,
    activeIcon: ShareIconSolid,
    current: false,
    conversationFilter: networkConversationFilter,
  },
  {
    name: 'Your Profile',
    route: 'profile',
    icon: BuildingOffice2Icon,
    isMobileOnly: true,
    activeIcon: BuildingOfficeIconSolid,
    current: false,
  },
];

const useMainNavigation = () => {
  const location = useLocation();
  const [sidebarNavItems, setSidebarNavItems] = useState([] as Array<ISidebarNavItem>);
  const { state } = useContext(AppContext);

  const organizationManifest = useEntityManifest(ResourceType.Organization);

  const organizationsQuery = useOrganizationsQuery();

  const updateMenu = () => {
    const currentRoute = location.pathname.substring(1).toLowerCase();

    const navItems = [
      ...staticNavItems.map((item) => ({
        ...item,
        numberOfUnreadNotifications:
          state.conversations?.length && item.conversationFilter
            ? state.conversations
                ?.filter(item.conversationFilter)
                .filter((m) => m.latestMessage?.isUnread === true).length
            : 0,

        current: currentRoute.startsWith(item.route.toLowerCase()),
      })),
      {
        name: 'My Companies',
        route: '',
        icon: BuildingOffice2Icon,
        activeIcon: BuildingOfficeIconSolid,
        isDisabled: true,
        current: false,
        contentBeforeChildren: <CompanyNavActions />,
        children: organizationsQuery.data?.pages?.flat().map(({ id, name, photoUrl }) => ({
          id,
          name,
          route: createRoute('companies', id),
          current: isCurrentRouteActive('companies', id, location.pathname),
          photoUrl,
          entityManifest: organizationManifest,
        })),
      },
    ];

    const navItemsWithSelectedParent = navItems.map((item) => {
      const itemCopy = { ...item };

      if (item.children && item.children.some((child) => child.current)) {
        itemCopy.current = true;
      }

      return itemCopy;
    });

    setSidebarNavItems(navItemsWithSelectedParent);
  };

  useEffect(() => {
    if (!organizationsQuery.data) return;
    if (!state.conversations) return;

    updateMenu();
  }, [organizationsQuery.data, location, state.conversations]);

  return sidebarNavItems;
};

export default useMainNavigation;
