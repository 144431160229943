import { useForm } from 'react-hook-form';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { ICreateUpdateOrganizationDto } from './models/ICreateUpdateOrganizationDto';
import {
  useCreateUpdateOrganizationMutation,
  useOrganizationQuery,
} from './queries/organizationQueries';
import ProfileAvatarForm from '../../shared/components/avatar/ProfileAvatarForm';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import { useOrganizationPhotoMutation } from './queries/organizationPhotoQueries';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import FlexContainer from '../../shared/components/structure/FlexContainer';

interface ICreateUpdateOrganizationFormData extends ICreateUpdateOrganizationDto {
  id?: string;
}
type UpdateValues = ICreateUpdateOrganizationFormData & { id: string };

interface IOrganizationFormProps {
  form: ReturnType<typeof useForm<ICreateUpdateOrganizationFormData>>;
  onSuccess?: (organizationId: string) => void;
  onCancel?: () => void;
  footerType?: React.ElementType;
}

interface IUseOrganizationCreateEditFormProps {
  values?: ICreateUpdateOrganizationFormData;
}

export function useOrganizationCreateEditForm({
  values,
}: IUseOrganizationCreateEditFormProps = {}) {
  return useForm<ICreateUpdateOrganizationFormData>({
    mode: 'onChange',
    values,
  });
}

export function CompanyCreateEditForm({
  form,
  onSuccess,
  onCancel,
  footerType: Footer = ButtonGroup,
}: IOrganizationFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
  } = form;

  const createUpdateOrganizationMutation = useCreateUpdateOrganizationMutation();

  const manifest = useEntityManifest(ResourceType.Organization);
  const photoMutations = useOrganizationPhotoMutation();

  const values = form.getValues() as UpdateValues;
  const isUpdate = !!values.id;
  const submitText = isUpdate ? 'Edit' : 'Create';

  const organization = useOrganizationQuery(values.id).data;

  const submitHandler = (data: ICreateUpdateOrganizationFormData) => {
    createUpdateOrganizationMutation.mutate(data, {
      onSuccess: ({ id }) => {
        if (onSuccess) {
          onSuccess(id);
        }
      },
    });
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <FlexContainer direction="column" gap="small">
        {isUpdate && (
          <ProfileAvatarForm
            url={(organization && organization.photoUrl) || manifest.icon}
            altText={values.name}
            onUpload={(file) => photoMutations.mutate({ id: values.id, file })}
            onRemove={() => photoMutations.mutate({ id: values.id })}
            isPending={photoMutations.isPending}
          />
        )}

        <TextFieldInput
          labelText="Name"
          name="name"
          register={register}
          errors={errors}
          validationRules={{
            required: 'Must enter a name',
            maxLength: { value: 400, message: 'Name cannot be longer than 400 characters' },
          }}
        />
        <TextAreaInput
          labelText="Description"
          name="description"
          register={register}
          errors={errors}
          validationRules={{
            maxLength: { value: 400, message: 'Description cannot be longer than 400 characters' },
          }}
        />
        <Footer>
          {onCancel && <Button color={ButtonColors.White} text="Cancel" onClick={handleCancel} />}
          <SubmitButton
            text={submitText}
            isDisabled={!isDirty || !isValid || createUpdateOrganizationMutation.isPending}
          />
        </Footer>
      </FlexContainer>
    </form>
  );
}
