import { useParams } from 'react-router';
import { useOrganizationsQuery } from '../queries/organizationQueries';
import { IDropdownNavigationItem } from '../../../shared/components/navigation/DropdownNavigator';

export default function useCompanyNavigation() {
  const organizationsQuery = useOrganizationsQuery();
  const { tenantId } = useParams();

  if (!organizationsQuery.isSuccess) {
    return { items: [] };
  }

  const companies = organizationsQuery.data?.pages
    ?.flat()
    .filter((company) => company.id !== tenantId);

  if (companies.length === 0) {
    return { items: [] };
  }

  const items: IDropdownNavigationItem[] = companies.map((company) => ({
    id: company.id,
    name: company.name,
    url: `/companies/${company.id}`,
  }));

  return { items };
}
