/* eslint-disable @typescript-eslint/no-empty-function */
import { Bars4Icon, ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline';
import { useMatch, useNavigate } from 'react-router-dom';

import { IActionItem } from '../../../shared/model/IActionItem';

export function useNetworkHeaderActions(): IActionItem[] {
  const navigate = useNavigate();
  const networksPathMatch = useMatch({ path: '/networks/*' });
  const networksListPathMatch = useMatch({ path: '/networks/list' });
  return [
    {
      id: 'view',
      title: 'Change view',
      icon: ChatBubbleOvalLeftEllipsisIcon,
      type: 'header',
    },
    networksPathMatch !== null && networksListPathMatch
      ? {
          id: 'social',
          title: 'Social view',
          icon: ChatBubbleOvalLeftEllipsisIcon,
          isActive: networksPathMatch !== null && networksListPathMatch === null,
          onClick: async () => navigate('/networks'),
        }
      : {
          id: 'list',
          title: 'List view',
          icon: Bars4Icon,
          isActive: networksListPathMatch !== null,
          onClick: async () => navigate('/networks/list'),
        },
  ];
}
