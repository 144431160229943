// eslint-disable-file no-param-reassign
import missingImage from '../../../assets/images/missing-image.png';
import { cn } from '../../../lib/utils';

export interface IProfileAvatarImageProps {
  src?: string;
  alt?: string;
  widthClass?: string;
  heightClass?: string;
}

export interface IProfileAvatarProps {
  avatarProps: IProfileAvatarImageProps;
  includeBorder?: boolean;
}

export default function ProfileAvatar({ avatarProps, includeBorder = false }: IProfileAvatarProps) {
  const imgUrl = avatarProps.src || '';
  return (
    <img
      src={imgUrl}
      onError={({ currentTarget }) => {
        const target = currentTarget;
        target.onerror = null; // prevents looping
        target.src = missingImage;
      }}
      alt={avatarProps.alt}
      className={cn(
        avatarProps.widthClass,
        avatarProps.heightClass,
        'rounded-full bg-gray-dark',
        includeBorder && 'ring-4 ring-white',
      )}
    />
  );
}
