import { useNavigate } from 'react-router';
import { CompanyCreateEditModal } from './CompanyCreateEditModal';
import useModal from '../../shared/components/modal/hooks/useModal';
import Tooltip from '../../shared/components/tooltip/Tooltip';
import Text from '../../shared/components/text/Text';
import ButtonLikeElement from '../../shared/components/buttons/ButtonLikeElement';
import AddIcon from '../../shared/components/icons/AddIcon';

export default function CompanyNavActions() {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();

  const handleCreateOrganizationSuccess = (organizationId: string) => {
    navigate(`/companies/${organizationId}`);
    closeModal();
  };

  const openEditCreateModal = () => {
    openModal({
      title: 'Add new company',
      modalSize: 'small',
      content: <CompanyCreateEditModal onSuccess={handleCreateOrganizationSuccess} />,
    });
  };

  return (
    <Tooltip message="Add company">
      <div>
        <ButtonLikeElement onClick={openEditCreateModal}>
          <AddIcon className="h-[35px] w-[35px] cursor-pointer relative left-[-2px] 2xl:left-[-4px]" aria-hidden="true" />
          <span className="hidden 2xl:block ml-2">
            <Text size="small">Add new company</Text>
          </span>
        </ButtonLikeElement>
      </div>
    </Tooltip>
  );
}
