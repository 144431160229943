/* eslint-disable jsx-a11y/label-has-for */
// FilterComponents.tsx
import React from 'react';
import { ChevronRight } from 'lucide-react';
import { FilterConfig, FiltersSettings } from './hooks/types';

import Label from '../text/Label';
import { RadioGroup, RadioGroupItem, RadioGroupItemWrapper } from '../radiobuttons/RadioGroup';
import FlexContainer from '../structure/FlexContainer';
import { Checkbox } from '../checkbox/Checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../drop-down-menu/DropdownMenu';

interface FilterProps<T> {
  filter: FilterConfig<T> & {
    value: any;
    onChange: (value: unknown) => void;
  };
  filterState: Partial<T>;
}

export function FilterComponent<T>({ filter, filterState }: FilterProps<T>) {
  const currentValue = filterState[filter.key as unknown as keyof T] as string;

  switch (filter?.type) {
    case 'radio': {
      return (
        <div>
          <Label text={filter.label} />
          <RadioGroup
            key={filter.label}
            value={currentValue}
            onValueChange={(value) => {
              filter.onChange(value);
            }}
          >
            {filter.options?.map((option) => (
              <RadioGroupItemWrapper key={option.value}>
                <RadioGroupItem value={option.value} id={option.value} />
                <Label htmlFor={option.value} text={option.label} />
              </RadioGroupItemWrapper>
            ))}
          </RadioGroup>
        </div>
      );
    }
    case 'checkbox':
      return (
        <FlexContainer>
          <Label text={filter.label} />
          {filter.options?.map(
            (option: {
              value: React.Key | readonly string[] | null | undefined;
              label:
                | string
                | number
                | boolean
                | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                | Iterable<React.ReactNode>
                | React.ReactPortal
                | null
                | undefined;
            }) => {
              const isChecked = (filter.value && filter.value.includes(option.value)) || false;

              return (
                <label key={option.value as string} className="flex flex-row items-center">
                  <Checkbox
                    value={option.value as string}
                    checked={isChecked}
                    onCheckedChange={(_isChecked) => {
                      let newValue = [...(filter.value || [])];
                      if (_isChecked) {
                        newValue.push(option.value);
                      } else {
                        newValue = newValue.filter((v) => v !== option.value);
                      }
                      filter.onChange(newValue);
                    }}
                  />
                  <span className="ml-2">{option.label}</span>
                </label>
              );
            },
          )}
        </FlexContainer>
      );
    case 'dropdown':
      return (
        <DropdownMenu>
          <DropdownMenuTrigger className="flex flex-row items-center">
            {filter.label} <ChevronRight className="w-4 h-4 relative top-0.5 left-1" />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {filter.options?.map((option) => (
              <DropdownMenuItem key={option.value} onClick={() => filter.onChange(option.value)}>
                {option.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      );
    default:
      return null;
  }
}

interface FilterContainerProps<TFilter> {
  filter: FiltersSettings<TFilter>;
}

export default function Filters<TFilter>({ filter }: FilterContainerProps<TFilter>) {
  return (
    <div className="grid grid-cols-2">
      {filter.filters.map((_filter) => (
        <FilterComponent
          key={_filter.key as string}
          filter={_filter}
          filterState={filter.filterState}
        />
      ))}
    </div>
  );
}
