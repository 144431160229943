/* eslint-disable @typescript-eslint/no-empty-function */
import { useNavigate } from 'react-router-dom';
import NetworkCreateEditModal from '../NetworkCreateEditModal';
import useModal from '../../../shared/components/modal/hooks/useModal';
import { IActionItem } from '../../../shared/model/IActionItem';
import AddIcon from '../../../shared/components/icons/AddIcon';

export function useNetworksActions(): IActionItem[] {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();

  const handleCreateNetworkSuccess = (networkId: string) => {
    navigate(networkId);
    closeModal();
  };

  return [
    {
      id: 'networks',
      title: 'Networks',
      type: 'header',
    },
    {
      id: 'create',
      title: 'Add network',
      icon: AddIcon,
      addDividerAfter: true,
      onClick: () => {
        openModal({
          title: 'Add network',
          content: (
            <NetworkCreateEditModal onSuccess={handleCreateNetworkSuccess} onCancel={closeModal} />
          ),
        });
      },
    },
  ];
}
