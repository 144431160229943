import { useFieldArray, useForm } from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import Button from '../../shared/components/buttons/Button';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import { ICreateContactDto } from './models/ICreateContactDto';
import { REGEX_EMAIL } from '../../shared/constants/RegexValidations';
import { useCreateContactMutation } from './queries/contactQueries';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import { UnsavedDataAlertDialog } from '../../shared/components/alert-dialog/UnsavedDataAlertDialog';
import { useOptionalControlledState } from '../../shared/hooks/useOptionalControlledState';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import { IContactDto } from './models/IContactDto';
import { useToast } from '../../shared/components/toasts/use-toast';

const baseDefaultValues: ICreateContactDto = {
  firstName: '',
  lastName: '',
  emailAddresses: [{ value: '', label: '' }],
  phoneNumbers: [],
  location: '',
  company: '',
  title: '',
  additionalInformation: '',
};

interface IUseContactCreateFormProps {
  defaultValues?: ICreateContactDto;
  values?: Partial<ICreateContactDto>;
}

export function useContactCreateForm({
  defaultValues: defaultValuesFromProps,
  values: valuesFromProps,
}: IUseContactCreateFormProps = {}) {
  const defaultValues = defaultValuesFromProps || baseDefaultValues;
  const values = { ...defaultValues, ...valuesFromProps };
  return useForm<ICreateContactDto>({
    mode: 'onChange',
    defaultValues,
    values,
  });
}

interface IProps {
  form: ReturnType<typeof useContactCreateForm>;
  unsavedDataWarningOpen?: boolean;
  onUnsavedDataWarningOpenChange?: Dispatch<SetStateAction<boolean>>;
  onSuccess: (data: IContactDto) => void;
  onCancel?: () => void;
}

export default function ContactCreateForm({
  form,
  unsavedDataWarningOpen: unsavedDataWarningOpenFromProps,
  onUnsavedDataWarningOpenChange: onUnsavedDataWarningOpenChangeFromProps,
  onSuccess,
  onCancel,
}: IProps) {
  const [unsavedDataWarningOpen, onUnsavedDataWarningOpenChange] = useOptionalControlledState(
    unsavedDataWarningOpenFromProps,
    onUnsavedDataWarningOpenChangeFromProps,
    false,
  );

  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isDirty, isValid, errors },
  } = form;

  const { fields } = useFieldArray({
    control,
    name: 'emailAddresses',
  });

  const createContactMutation = useCreateContactMutation();

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleContinueClosing = () => {
    reset();
    handleCancel();
  };

  const handleCancelSaveContact = () => {
    if (isDirty) {
      onUnsavedDataWarningOpenChange(true);
    } else {
      reset();
      handleCancel();
    }
  };

  const submit = (_contact: ICreateContactDto) => {
    const contactClone = { ..._contact };

    // There is automatically an empty default value added to phoneNumbers array
    // i.e. [{ value: '' }]. We need to remove this empty value before submitting
    // Look for a better way of solving this later
    contactClone.phoneNumbers = contactClone.phoneNumbers.filter(
      (phoneNumber) => phoneNumber.value !== '',
    );

    createContactMutation.mutate(
      { contact: contactClone },
      {
        onSuccess: (_result) => {
          reset();
          if (onSuccess) {
            toast({
              title: 'Success',
              description: 'The contact has been created successfully',
              variant: 'success',
            });
            onSuccess(_result);
          }
        },
      },
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className="block">
        <div className="grid grid-cols-2 gap-x-4 ">
          <div>
            <TextFieldInput
              labelText="First Name"
              name="firstName"
              type="text"
              register={register}
              errors={errors}
              validationRules={{
                required: 'Required and between 2 and 50 characters',
                minLength: { value: 2, message: 'Required and between 2 and 50 characters' },
                maxLength: { value: 50, message: 'Required and between 2 and 50 characters' },
              }}
            />
          </div>
          <div>
            <TextFieldInput
              labelText="Last Name"
              name="lastName"
              type="text"
              register={register}
              errors={errors}
              validationRules={{
                required: 'Required and between 2 and 50 characters',
                minLength: { value: 2, message: 'Required and between 2 and 50 characters' },
                maxLength: { value: 50, message: 'Required and between 2 and 50 characters' },
              }}
            />
          </div>

          <div>
            {fields.map((field, index) => (
              <TextFieldInput
                key={field.id}
                labelText="Email"
                name={`emailAddresses.${index}.value`}
                type="text"
                register={register}
                errors={errors}
                validationRules={{
                  pattern: { value: REGEX_EMAIL, message: 'Must be a valid email' },
                  required: 'Email is required',
                }}
              />
            ))}
          </div>
          <div>
            <TextFieldInput
              labelText="Phone Number"
              name="phoneNumbers.0.value"
              type="text"
              register={register}
            />
          </div>

          <div>
            <TextFieldInput labelText="Location" name="location" type="text" register={register} />
          </div>

          <div />

          <div>
            <TextFieldInput labelText="Title" name="title" type="text" register={register} />
          </div>

          <div>
            <TextFieldInput labelText="Company" name="company" type="text" register={register} />
          </div>
        </div>

        <div className="mt-6 grid grid-cols-1">
          <div className="mt-1">
            <TextAreaInput
              name="additionalInformation"
              register={register}
              labelText="Additional Information"
            />
          </div>
        </div>
        <div className="pt-5">
          <FlexContainer justify="end">
            <Button color={ButtonColors.White} text="Cancel" onClick={handleCancelSaveContact} />
            <SubmitButton text="Create" isDisabled={!isValid || createContactMutation.isPending} />
          </FlexContainer>
        </div>
      </form>
      <UnsavedDataAlertDialog
        open={unsavedDataWarningOpen}
        onOpenChange={onUnsavedDataWarningOpenChange}
        onLeaveClick={handleContinueClosing}
      />
    </>
  );
}
