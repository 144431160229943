import { Outlet, Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import UrlNotFound from './shared/components/error-boundary/UrlNotFound';
import Profile from './features/Profile/Profile';
import ErrorBoundary from './shared/components/error-boundary/ErrorBoundary';
import Index from './features/Index/Index';
import { SignIn } from './features/SignUpSignIn/SignIn';
import { SignUp } from './features/SignUpSignIn/SignUp';
import AcceptInvitation from './features/SignUpSignIn/AcceptInvitation';
import SelectPrompt from './shared/components/prompts/SelectPrompt';
import pipelineDummy from './assets/mocks/pipelinesMock.png';
import ProjectsSearchBoards from './features/Projects/ProjectsSearchBoards';
import Board from './features/Projects/Board';
import { AuthenticatedLayout } from './shared/components/layout/application/AuthenticatedLayout';
import { NetworkDetailList } from './features/Networks/NetworksDetailList';
import { NetworkDetail } from './features/Networks/NetworkDetail';
import { NetworksList } from './features/Networks/NetworksList';
import { ContactsDetailList } from './features/Contacts/ContactsDetailList';
import { ContactDetail } from './features/Contacts/ContactDetail';
import Company from './features/Companies/Company';

function Routes() {
  return (
    <ErrorBoundary>
      <ReactRouterDomRoutes>
        <Route path="signin" element={<SignIn />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="acceptinvitation/:invitationId" element={<AcceptInvitation />} />
        <Route path="/*" element={<AuthenticatedLayout />}>
          <Route index element={<Index />} />
          <Route path="contacts" element={<ContactsDetailList />}>
            <Route path=":id" element={<ContactDetail />} />
            <Route
              path="*"
              element={
                <SelectPrompt
                  imageUrl={pipelineDummy}
                  text={
                    <>
                      Invite your contacts to start chatting, sharing files, and more.
                      <br /> Click on the plus icon now to get started
                    </>
                  }
                />
              }
            />
          </Route>

          <Route path="networks/list" element={<NetworksList />} />
          <Route path="networks" element={<NetworkDetailList />}>
            <Route path=":id" element={<NetworkDetail />} />
            <Route
              path="*"
              element={
                <SelectPrompt
                  imageUrl={pipelineDummy}
                  text={
                    <>
                      Start building your place of business by creating a space to chat, share and
                      work with ideas.
                      <br /> Click on the plus icon and let the collaboration begin
                    </>
                  }
                />
              }
            />
          </Route>
          {/* We need a seperate route for boards to work-around outlet
            and routing that mess up sidebar handling
            ONLY projectId to workaround baseUrl checks for mobile layout
            */}

          <Route path="profile" element={<Profile />} />
          <Route path="companies">
            <Route path=":tenantId" element={<Outlet />}>
              <Route index element={<Company />} />
              <Route path="projects/:projectId" element={<Outlet />}>
                <Route index element={<ProjectsSearchBoards />} />
                <Route path="boards/:boardId" element={<Board />} />
              </Route>
            </Route>
            <Route
              path="*"
              element={
                <SelectPrompt
                  imageUrl={pipelineDummy}
                  text={<>Create your organization to get started with collaboration.</>}
                />
              }
            />
          </Route>
          <Route path="*" element={<UrlNotFound />} />
        </Route>
      </ReactRouterDomRoutes>
    </ErrorBoundary>
  );
}

export default Routes;
